import { faker } from "@faker-js/faker/locale/de"
import { Container } from '@mui/material'
import axios from "axios"
import { useEffect, useState } from 'react'
import { API_BASE_URL } from ".."
import { Category } from './components/Category'
import { EntryType } from './components/Entry'
import { LetterTab } from './components/LetterTab'
import { SearchBar } from './components/SearchBar'


const getEntries = async (updateEntries: (entries: EntryType[]) => void) => {
	const list = []
	for (var i = 0; i < 100; i++) {
		var address = faker.address;
		var name = faker.name;
		var internet = faker.internet;
		var phone = faker.phone;
		list.push({
			category: name.jobType(),
			name: name.fullName(),
			information: [
				address.zipCode() + " " + address.cityName(),
				address.streetAddress(),
				internet.email(),
				phone.number(),
				internet.url()
			]
		})
	}
	const data: EntryType[] = await axios.get(API_BASE_URL + "load/")
		.then((response: { data: { category: string, name: string, information: string }[] }) =>
			response.data.map(entry => {
				const e: EntryType = { ...entry, ...JSON.parse(entry.information) }
				return e
			}))
		.catch(e => {
			console.error(e)
			return []
		})
	updateEntries(data)
}

export function User() {
	const [entries, setEntries] = useState<EntryType[]>([])
	const [letter, setLetter] = useState("_")
	useEffect(() => {
		getEntries(updateEntries)
	}, [])
	const updateEntries = (entries: EntryType[]) => setEntries(entries)
	try {
		const categories = [...new Set(entries.map(entry => entry.category))].sort((a, b) => a.localeCompare(b)).filter(category => category[0].toUpperCase() === letter || letter === "_")

		return (<>
			<LetterTab
				letters={[...new Set(entries.map(entry => entry.category[0].toUpperCase()))]}
				letter={letter}
				setLetter={setLetter}
			/>
			<Container maxWidth="xl" sx={{ px: "4px" }}>
				{letter === "search" &&
					<SearchBar entries={entries} />}
				{categories.map((category, key) => <Category key={key} name={category} entries={entries.filter(entry => entry.category === category)} />)}
			</Container>
		</>)
	} catch {
		return <></>
	}
}