import { Search } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type Props = {
	letters: string[],
	letter: string,
	setLetter: (letter: string) => void
}

export const LetterTab = ({ letters, letter, setLetter }: Props) =>
	<ToggleButtonGroup
		value={letter}
		exclusive
		fullWidth
		onChange={(_event, value) => setLetter(value)}
	>
		{letters.sort().map(letter => <ToggleButton key={letter} value={letter}>{letter}</ToggleButton>)}
		<ToggleButton value="_">Alle</ToggleButton>
		<ToggleButton value="search"><Search /></ToggleButton>
	</ToggleButtonGroup>

